<template>
	<section>
		<div class="card">
			<div class="card-header">
				<div class="card-title h4">Mi Perfil</div>
			</div>
			<div class="card-body">
				<div class="columns my-2">
					<div class="column col-md-12 col-3 form-group text-center">
						<img :src="avatar" alt="Avatar" class="foto img-responsive">
						<button class="btn btn-primary btn-sm my-2" @click="abrirAvatar">Cambiar Foto</button>
					</div>
					<div class="column col-md-12 col-9">
						<div class="columns">
							<div class="column col-md-12 col-6">
								<label class="form-label text-bold">Tipo</label>
								<p>{{ usuario.tipo_nombre }}</p>
							</div>
							<div class="column col-md-12 col-6">
								<label class="form-label text-bold">Correo</label>
								<p>{{ usuario.correo }}</p>
							</div>
							<div class="column col-md-12 col-6 form-group">
								<label class="form-label text-bold">Nombre</label>
								<input class="form-input" type="text" v-model="usuario.nombre">
							</div>
							<div class="column col-md-12 col-6 form-group">
								<label class="form-label text-bold">Cédula</label>
								<input class="form-input" type="text" v-model="usuario.cedula">
							</div>
							<div class="column col-md-12 col-6 form-group">
								<label class="form-label text-bold">Teléfono</label>
								<input class="form-input" type="text" v-model="usuario.telefono">
							</div>
							<div class="column col-12">
								<label class="form-label text-bold">Dirección</label>
								<textarea class="form-input" v-model="usuario.direccion" rows="3"></textarea>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<UploadAvatar
			v-model="modalAvatar"
			lang-type="es-MX"
			:width="512"
			:height="512"
			:url="$api + 'usuario/subir_avatar'"
			@crop-success="cortarAvatar"
			@crop-upload-success="subirAvatar"
			:headers="{ 'Wuipi-Token': verToken }"
		/>
	</section>
</template>

<script>
	import UploadAvatar from 'vue-image-crop-upload/upload-2.vue';
	export default {
		name: 'Perfil',
		components: { UploadAvatar },
		data() {
			return {
				cargando: false,
				usuario: {},
				modalAvatar: false,
				avatar: '',
			}
		},
		mounted() {
			document.title = 'Mi Perfil';
			this.avatar = require('@/assets/img/cargando_avatar.jpg');
			this.infoUsuario();
			this.cargarUsuario();
		},
		methods: {
			infoUsuario() {
				if(Object.keys(this.$store.state.user).length === 0) {
					this.$store.state.api
						.get('usuario')
						.then(res => this.$store.commit('setUser', res.data))
						.catch(err => alert(err.response.data.mensaje));
				}
			},
			cargarUsuario() {
				this.cargando = true;
				this.$store.state.api
					.get('usuario')
					.then(res => {
						this.usuario = res.data;
						this.avatar = res.data.avatar == 1
							? `${this.$api}avatar/${res.data.id}.png`
							: require('@/assets/img/avatar.jpg');
					})
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargando = false);
			},
			abrirAvatar() {
				this.modalAvatar = !this.modalAvatar;
			},
			cortarAvatar(img) {
				this.avatar = img;
			},
			subirAvatar(json) {
				this.cargarUsuario();
			},
			errorSubirAvatar(estado) {
				this.avatar = require('@/assets/img/avatar.jpg');
			}
		},
		computed: {
			verToken() {
				return localStorage.getItem('WuipiToken') == null
					? ''
					: localStorage.getItem('WuipiToken');
			}
		}
	}
</script>

<style scoped>
	.foto {
		border-radius: 50%;
	}
</style>